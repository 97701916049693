import React, { useEffect, useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useParams } from 'react-router-dom';

function AssetDetails() {
  const { id } = useParams();
  const [assetData, setAssetData] = useState(null);
  const [error, setError] = useState(null);
  const [qrSize, setQrSize] = useState(128); // Initial QR code size state
  const [showUpdateForm, setShowUpdateForm] = useState(false); // State to toggle the update form
  const [updatedAssetData, setUpdatedAssetData] = useState({
    asset_id: id,
    asset_description: '',
    asset_make: '',
    vendor_name: '',
    invoice_number: '',
    invoice_date: '',
    installation_date: '',
    location: '',
    warrantee: '',
  }); // State for the form values

  const qrRef = useRef();

  useEffect(() => {
    console.log(id);
    // Fetch data from the API endpoint
    fetch(`https://hellovictim.site/assets/constomer/getByIdAssets.php?asset_id=${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setAssetData(data);
        setUpdatedAssetData({
          ...updatedAssetData,
          asset_description: data.asset_description,
          asset_make: data.asset_make,
          vendor_name: data.vendor_name,
          invoice_number: data.invoice_number,
          invoice_date: data.invoice_date,
          installation_date: data.installation_date,
          location: data.location,
          warrantee: data.warrantee,
        });
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [id]);

  
 

  const handleDownloadQR = () => {
    const qrCanvas = qrRef.current.querySelector('canvas');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Define dimensions for the new canvas (QR code + serial number space)
    const text = `Serial Number: ${assetData.asset_id_no}`;
    const textHeight = 20;
    const padding = 10;

    canvas.width = qrCanvas.width;
    canvas.height = qrCanvas.height + textHeight + padding;

    // Draw the QR code
    ctx.drawImage(qrCanvas, 0, 0);

    // Add the serial number below the QR code
    ctx.font = '16px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.fillText(
      text,
      canvas.width / 2,
      qrCanvas.height + textHeight / 2 + padding / 2
    );

    // Create a downloadable image
    const qrImageWithText = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = qrImageWithText;
    link.download = `qr-code-serial-${assetData.asset_id_no}.png`;
    link.click();
  };

  const handlePrintQR = () => {
    const qrCanvas = qrRef.current.querySelector('canvas');
    const printWindow = window.open('', '_blank');
    const text = `Serial Number: ${assetData.asset_id_no}`;

    printWindow.document.write(`
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              text-align: center;
              margin: 20px;
            }
            canvas {
              display: block;
              margin: 0 auto;
            }
            p {
              margin-top: 10px;
              font-size: 16px;
              color: #333;
            }
          </style>
        </head>
        <body>
          <canvas></canvas>
          <p>${text}</p>
        </body>
      </html>
    `);

    const printCanvas = printWindow.document.querySelector('canvas');
    const printContext = printCanvas.getContext('2d');

    // Set the dimensions of the print canvas
    printCanvas.width = qrCanvas.width;
    printCanvas.height = qrCanvas.height;

    // Draw the QR code onto the new canvas
    printContext.drawImage(qrCanvas, 0, 0);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const increaseQrSize = () => {
    setQrSize((prevSize) => prevSize + 16); // Increase size by 16px
  };

  const decreaseQrSize = () => {
    setQrSize((prevSize) => (prevSize > 32 ? prevSize - 16 : prevSize)); // Decrease size by 16px, minimum 32px
  };

  const handleChange = (e) => {
    setUpdatedAssetData({
      ...updatedAssetData,
      [e.target.name]: e.target.value,
    });
  };
  const handleUpdate = () => {
    const secretCode = window.prompt("Enter the secret code to update this asset:");
    if (secretCode === "1180") {
      setShowUpdateForm(true); // Show the form when the update button is clicked
    } else {
      alert("Incorrect secret code. You are not authorized to update this asset.");
    }
  };
  
  const handleDelete = () => {
    const secretCode = window.prompt("Enter the secret code to delete this asset:");
    if (secretCode === "1180") {
      const confirmation = window.confirm("Are you sure you want to delete this asset?");
      if (confirmation) {
        fetch(`https://hellovictim.site/assets/constomer/deleteAssets.php?asset_id=${id}`, {
          method: 'DELETE',
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(() => {
            alert("Asset deleted successfully!");
            window.location.href = "/"; // Redirect to home page
          })
          .catch((err) => {
            alert("Error deleting asset");
            console.error(err);
          });
      }
    } else {
      alert("Incorrect secret code. You are not authorized to delete this asset.");
    }
  };
  


  

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://hellovictim.site/assets/constomer/editAssets.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedAssetData),
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Asset updated successfully!');
        setShowUpdateForm(false); // Hide the update form
        // Optionally, you can reload the asset data or perform other actions
      })
      .catch((err) => {
        alert('Error updating asset');
        console.error(err);
      });
  };

  

  const pageUrl = window.location.href; // Current page URL

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Asset Details</h1>
      {error && <p style={{ ...styles.text, ...styles.error }}>Error: {error}</p>}
      {assetData ? (
        <div style={styles.card}>
          <p style={styles.text}><strong>Asset ID:</strong> {assetData.asset_id_no}</p>
          <p style={styles.text}><strong>Description:</strong> {assetData.asset_description}</p>
          <p style={styles.text}><strong>Make:</strong> {assetData.asset_make}</p>
          <p style={styles.text}><strong>Vendor Name:</strong> {assetData.vendor_name}</p>
          <p style={styles.text}><strong>Invoice Number:</strong> {assetData.invoice_number}</p>
          <p style={styles.text}><strong>Invoice Date:</strong> {assetData.invoice_date}</p>
          <p style={styles.text}><strong>Installation Date:</strong> {assetData.installation_date}</p>
          <p style={styles.text}><strong>Location:</strong> {assetData.location}</p>
          <p style={styles.text}><strong>Warranty:</strong> {assetData.warrantee}</p>

          <div style={styles.buttonContainer}>
            <button style={styles.button} onClick={handleUpdate}>Update</button>
            <button style={styles.button} onClick={handleDelete}>Delete</button>
          </div>

          {/* Update Form */}
          {showUpdateForm && (
            <form onSubmit={handleSubmit} style={styles.form}>
              <label style={styles.formLabel}>Asset Description:</label>
              <input
                type="text"
                name="asset_description"
                value={updatedAssetData.asset_description}
                onChange={handleChange}
                style={styles.formInput}
              />

              <label style={styles.formLabel}>Make:</label>
              <input
                type="text"
                name="asset_make"
                value={updatedAssetData.asset_make}
                onChange={handleChange}
                style={styles.formInput}
              />

              <label style={styles.formLabel}>Vendor Name:</label>
              <input
                type="text"
                name="vendor_name"
                value={updatedAssetData.vendor_name}
                onChange={handleChange}
                style={styles.formInput}
              />

              <label style={styles.formLabel}>Invoice Number:</label>
              <input
                type="text"
                name="invoice_number"
                value={updatedAssetData.invoice_number}
                onChange={handleChange}
                style={styles.formInput}
              />

              <label style={styles.formLabel}>Invoice Date:</label>
              <input
                type="date"
                name="invoice_date"
                value={updatedAssetData.invoice_date}
                onChange={handleChange}
                style={styles.formInput}
              />

              <label style={styles.formLabel}>Installation Date:</label>
              <input
                type="date"
                name="installation_date"
                value={updatedAssetData.installation_date}
                onChange={handleChange}
                style={styles.formInput}
              />

              <label style={styles.formLabel}>Location:</label>
              <input
                type="text"
                name="location"
                value={updatedAssetData.location}
                onChange={handleChange}
                style={styles.formInput}
              />

              <label style={styles.formLabel}>Warranty:</label>
              <input
                type="text"
                name="warrantee"
                value={updatedAssetData.warrantee}
                onChange={handleChange}
                style={styles.formInput}
              />

              <div style={styles.formActions}>
                <button type="submit" style={styles.button}>Submit Update</button>
                <button type="button" onClick={() => setShowUpdateForm(false)} style={styles.button}>Cancel</button>
              </div>
            </form>
          )}

          <div style={styles.qrContainer} ref={qrRef}>
            <h3 style={styles.text}>QR Code</h3>
            <div style={styles.qrContent}>
              <QRCodeCanvas value={pageUrl} size={qrSize} />
            </div>
            <p style={styles.text}>Serial Number: {assetData.asset_id_no}</p>
            <p style={styles.text}>Click the buttons below to download or print the QR code:</p>
            <button style={styles.button} onClick={handleDownloadQR}>Download QR Code</button>
            <button style={styles.button} onClick={handlePrintQR}>Print QR Code</button>

            {/* Buttons for increasing and decreasing QR code size */}
            <div style={styles.sizeControl}>
              <button style={styles.button} onClick={increaseQrSize}>Increase Size</button>
              <button style={styles.button} onClick={decreaseQrSize}>Decrease Size</button>
            </div>
          </div>
        </div>
      ) : (
        <p style={styles.text}>Loading asset data...</p>
      )}
    </div>
  );
}

// CSS styles as a JavaScript object
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    color: '#333',
    fontSize: '2rem',
    marginBottom: '20px',
  },
  text: {
    fontSize: '1rem',
    color: '#555',
    margin: '5px 0',
  },
  error: {
    color: 'red',
  },
  card: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '500px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  button: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  form: {
    marginTop: '20px',
  },
  formLabel: {
    display: 'block',
    margin: '10px 0 5px',
    fontWeight: 'bold',
  },
  formInput: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  qrContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  qrContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  sizeControl: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  },
};

export default AssetDetails;
