import React, { useState } from "react";

const Login = () => {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Clear any previous message

    const loginData = {
      number,
      password,
    };

    try {
      const response = await fetch(
        "https://hellovictim.site/assets/constomer/Login.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );

      const result = await response.json();

      if (response.ok && result.message === "Login successful") {
        // Store user data in localStorage
        localStorage.setItem("user", JSON.stringify(result.user));
        // Redirect to home page
        window.location.href = "/home"; // Replace with your actual home page route
      } else {
        setMessage(result.message || "Invalid login credentials");
      }
    } catch (error) {
      setMessage(`Network error: ${error.message}`);
    }
  };

  const handleRedirect = (path) => {
    window.location.href = path; // Redirect to the specified path
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "20px" }}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label>
            Number:
            <input
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              required
            />
          </label>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              required
            />
          </label>
        </div>
        <button
          type="submit"
          style={{
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            backgroundColor: "#007BFF",
            color: "#fff",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        >
          Login
        </button>
      </form>
      {message && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid",
            borderRadius: "4px",
            backgroundColor: "#f8f9fa",
            color: "#333",
          }}
        >
          {message}
        </div>
      )}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <button
          onClick={() => handleRedirect("/register")}
          style={{
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            backgroundColor: "#28a745",
            color: "#fff",
            cursor: "pointer",
            margin: "5px",
          }}
        >
          Register Now
        </button>
        <button
          onClick={() => handleRedirect("/ForgetPassword")}
          style={{
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            backgroundColor: "#ffc107",
            color: "#fff",
            cursor: "pointer",
            margin: "5px",
          }}
        >
          Forget Password
        </button>
      </div>
    </div>
  );
};

export default Login;
