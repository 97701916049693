import React, { useState } from "react";

const ForgetPassword = () => {
  const [number, setNumber] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!number || !newPassword) {
      setMessage("Please fill in all fields.");
      return;
    }

    const forgetData = {
      number,
      new_password: newPassword,
    };

    try {
      const response = await fetch(
        "https://hellovictim.site/assets/constomer/forget.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(forgetData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setMessage("Password updated successfully.");
      } else {
        setMessage(result.error || "Something went wrong.");
      }
    } catch (error) {
      setMessage(`Network error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <h2>Forgot Password</h2>
      <form onSubmit={handleForgetPassword} style={styles.form}>
        <div style={styles.inputGroup}>
          <label>Number:</label>
          <input
            type="text"
            placeholder="Enter your number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.inputGroup}>
          <label>New Password:</label>
          <input
            type="password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={styles.input}
            required
          />
        </div>
        <button type="submit" style={styles.button}>
          Reset Password
        </button>
      </form>
      {message && <div style={styles.message}>{message}</div>}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "center",
  },
  form: {
    marginTop: "20px",
  },
  inputGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#007BFF",
    color: "#fff",
    cursor: "pointer",
  },
  message: {
    marginTop: "20px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#f8f9fa",
  },
};

export default ForgetPassword;
