import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function HomeMain() {
  const [getAllData, setGetAllData] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // For controlling modal visibility
  const [newAsset, setNewAsset] = useState({
    asset_description: "",
    asset_make: "",
    vendor_name: "",
    invoice_number: "",
    invoice_date: "",
    installation_date: "",
    location: "",
    warrantee: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    try {
      const response = await fetch(
        "https://hellovictim.site/assets/constomer/getAllAssets.php"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setGetAllData(data);
    } catch (error) {
      console.error("API Error:", error);
      setError(error.message);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem("user"); // Clear the user data
    navigate("/login"); // Redirect to login page
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAsset({
      ...newAsset,
      [name]: value,
    });
  };

  const handleAddAsset = async () => {
    try {
      const response = await fetch(
        "https://hellovictim.site/assets/constomer/AddAssets.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newAsset),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // After adding, fetch the updated data and close the modal
      fetchAllData();
      setIsModalOpen(false);
      setNewAsset({
        asset_description: "",
        asset_make: "",
        vendor_name: "",
        invoice_number: "",
        invoice_date: "",
        installation_date: "",
        location: "",
        warrantee: "",
      });
    } catch (error) {
      console.error("Error adding asset:", error);
      setError(error.message);
    }
  };

  const filteredData = getAllData.filter((item) =>
    item.invoice_number.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={styles.container}>
      {/* Header Section */}
      <div style={styles.headerContainer}>
        <div style={styles.logoContainer}>
          <h1 style={styles.title}>Welcome To Yes Seal Trust</h1>
        </div>
        <div style={styles.rightHeader}>
          <p style={styles.totalAssetsText}>
            <strong>Total Assets:</strong> {getAllData.length}
          </p>
          <button style={styles.logoutButton} onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>

      {/* Actions Section */}
      <div style={styles.actionsContainer}>
        <button style={styles.addButton} onClick={handleModalToggle}>
          Add
        </button>
        <input
          type="text"
          placeholder="Search by Invoice Number"
          value={searchQuery}
          onChange={handleSearch}
          style={styles.searchInput}
        />
      </div>

      {/* Assets List Section */}
      <h2 style={styles.assetsListHeader}>Assets List</h2>
      <div style={styles.cardContainer}>
        {error && <p style={styles.error}>Error: {error}</p>}
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <div key={index} style={styles.card}>
              <p style={styles.text}>{item.invoice_number}</p>
              <button
                style={styles.getButton}
                onClick={() => navigate(`/asset-details/${item.asset_id_no}`)}
              >
                get
              </button>
            </div>
          ))
        ) : (
          <p style={styles.text}>No assets found.</p>
        )}
      </div>

      {/* Modal for adding asset */}
      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h3>Add Asset</h3>
            <form style={styles.modalForm}>
              <input
                type="text"
                name="asset_description"
                placeholder="Asset Description"
                value={newAsset.asset_description}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <input
                type="text"
                name="asset_make"
                placeholder="Asset Make"
                value={newAsset.asset_make}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <input
                type="text"
                name="vendor_name"
                placeholder="Vendor Name"
                value={newAsset.vendor_name}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <input
                type="text"
                name="invoice_number"
                placeholder="Invoice Number"
                value={newAsset.invoice_number}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <input
                type="date"
                name="invoice_date"
                placeholder="Invoice Date"
                value={newAsset.invoice_date}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <input
                type="date"
                name="installation_date"
                placeholder="Installation Date"
                value={newAsset.installation_date}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <input
                type="text"
                name="location"
                placeholder="Location"
                value={newAsset.location}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <input
                type="text"
                name="warrantee"
                placeholder="Warranty"
                value={newAsset.warrantee}
                onChange={handleInputChange}
                style={styles.modalInput}
              />
              <button
                type="button"
                onClick={handleAddAsset}
                style={styles.modalButton}
              >
                Add Asset
              </button>
              <button
                type="button"
                onClick={handleModalToggle}
                style={styles.modalButton}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "1.5rem",
    color: "#333",
  },
  rightHeader: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  totalAssetsText: {
    fontSize: "1rem",
    color: "#333",
  },
  logoutButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  addButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  searchInput: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    flex: 1,
  },
  assetsListHeader: {
    fontSize: "1.25rem",
    marginBottom: "10px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "5px",
  },
  text: {
    fontSize: "1rem",
    color: "#555",
  },
  getButton: {
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    fontSize: "1rem",
    marginBottom: "20px",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    width: "400px",
    textAlign: "center",
  },
  modalForm: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  modalInput: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  modalButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default HomeMain;
