import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomeMain from './HomeMain/HomeMain'; // Your HomeMain component
import AssetDetails from './AssetDetails/AssetDetails';
import Login from './Login/Login';
import Register from './Register/Register';
import ForgetPassword from './ForgetPassword/ForgetPassword';

function App() {
  // Function to check if the user is logged in
  const isLoggedIn = () => {
    const user = localStorage.getItem("user");
    return user !== null; // If user data exists, return true
  };

  return (
    <Router>
      <div>
        <Routes>
          {/* If logged in, go to HomeMain; otherwise, go to Login */}
          <Route
            path="/"
            element={isLoggedIn() ? <Navigate to="/home" /> : <Login />}
          />
          {/* Home page route */}
          <Route
            path="/home"
            element={isLoggedIn() ? <HomeMain /> : <Navigate to="/" />}
          />
          {/* Other routes */}
          <Route
            path="/asset-details/:id"
            element={isLoggedIn() ? <AssetDetails /> : <Navigate to="/" />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
